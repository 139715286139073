###

         dP oo       dP
         88          88
.d8888b. 88 dP .d888b88 .d8888b. .d8888b.
Y8ooooo. 88 88 88'  `88 88ooood8 88'  `88
      88 88 88 88.  .88 88.  ... 88.  .88
`88888P' dP dP `88888P8 `88888P' `88888P8
oooooooooooooooooooooooooooooooooooooooooo

@plugin    jQuery
@license   CodeCanyon Standard / Extended
@author    Alex Grozav
@company   Pixevil
@website   http://pixevil.com
@email     alex@grozav.com
@guide
  Running slidea
    $('.slidea').slidea
      width: 1280
      height: 720
      layout: 'layout_name'

  Using methods
    $('.slidea').data('slidea').method_name()

  Adding events
    $('.slidea').on 'event_name', ->
###

(($, window, document) ->
  "use strict"

  # @Slidea
  $.slidea = (element, options) ->

    # Default attribute values
    _defaults =
      width: 1280 # Slide canvas width
      height: 720 # Slide canvas height
      animation:
        in: "opacity 0" # Slide initial animation state
        out: "fade out" # Slide default out animation
        easing: "swing" # Default animation easing
        duration: 500 # Default animation duration

      delay: 4000 # Slide delay / display time

      overlap: 1 # Overlap previous out and current in animations (value >= 0)
                 # where 0 means overlap and 1 means wait for transition to finish

      layout: "default" # Slidea layout
      layout_settings: {} # Layout initialization settings

      layer_index: 99 # Starting z-index for layers

      autoplay: true # Autoplay feature
      pause_on_hover: false # Pause autoplay on hover
      loop: true # Start from first slide after reaching last

      content_scaling: false # Scale content based on parent width
      content_scaling_factor: 1 # Scale multiplication coefficient
      content_width: null # Set content reference width. Default: canvas width

      content_parallax: true # Fade content on scroll
      content_parallax_data:
        mode: 'from-middle'
        translate_y: 0.6 # Scroll fade translate speed coefficient
        opacity: 0.4 # Scroll fade fade speed coefficient
        opacity_mode: 'default' # from-middle, to-middle, default

      grid:           # Split slidea background into tiles
        rows: 1       # Number of rows to split the background into
        columns: 1    # Number of columns to split the background into
        stagger: 100  # Animation delay in between tiles

      parallax: true # Parallax scroll coefficient
      parallax_data:
        translate_y: 0.2
      parallax_layers: true # Parallax layers as well

      touch: true # Touch controls
      keyboard: true # Slide next / prev with keyboard arrows
      mousewheel: false # Enable Slide on Scroll
      prevent_scrolling: true # Prevent page scrolling if mousewheel enabled

      loader: true # Add a loader if it doesn't exist in the markup

      scroller: false # Enable Scroller Item
      scroller_markup: "<span class=\"slidea-scroller-1\"></span>" # Markup for scroller item (1 or 2)
      scroller_position: "center" # Scroller position: left, center, right

      progress: true # Add progress bar
      progress_position: "bottom" # Progress bar position top / bottom
      progress_class: "slidea-progress-light" # Additional thumbnail classes

      thumbnails: false # Add thumbnails
      thumbnails_visible: # Maximum number of thumbnails on page
        phone: 3
        tablet: 4
        laptop: 5
        desktop: 6
      thumbnails_position: "after" # Thumbnails position before or after
      thumbnails_class: "" # Additional thumbnail classes

      controls: true # Add next / prev buttons
      controls_thumbnail: true # Add thumbnail to controls
      controls_html:
        prev: "&lt;"
        next: "&gt;"
      controls_class: "slidea-controls-alternate" # Additional control classes

      pagination: false # Add pagination
      pagination_position: "inside" # Pagination position outside / inside
      pagination_class: "slidea-pagination-light" # Additional pagination classes

      prevent_dragging: true # Prevent image dragging

      selector: # Element selectors
        slide: ".slide" # Should not be changed unless absolutely necessary
        content: ".content"
        background: ".background"
        video_background: ".video-background"
        video: ".video"
        video_cover: ".video-cover"
        layer: ".layer"
        object: ".object"
        next: ".next"
        prev: ".prev"


    @debug = true

    # Set @options
    @_defaults = _defaults
    @settings = $.extend {}, _defaults, options

    # Set current @element
    @context = $ element

    # Animation States
    @animation = {}

    # Slider
    @element = $ element
    @parent = @element.parent()
    @current = -1

    # Get slider ID
    @id = if @element.attr('id')?
      @element.attr 'id'
    else
      'slidea-' + Math.floor((Math.random() * 1000) + 1)

    # Window
    @window = $ window

    # Cache
    @cache = {}

    # Video Players
    @youtube_player = {}
    @vimeo_player = {}

    # Timer
    # Used in slide to set timeout to next slide
    @timer = {}
    @timer.timeout = null
    @timer.start = 0
    @timer.remaining = 0


    # Sets the @default variables and animations
    #
    @initialize = =>
      # @Loader
      @add_loader() if @settings.loader

      # Animation States
      @init_animus()

      # @Settings
      @set_data_settings()

      # Add Slidea Classes
      @add_classes()

      # Wrap Objects
      @wrap_objects()

      # Set inner
      @inner = $ ".slidea-inner", @element

      # Set outer
      @outer = $ ".slidea-outer", @element

      # Set Slides
      @slides = $ ".slidea-slide", @element
      @slides_length = @slides.length

      # Set Active Slide
      @active = @slides.eq(0)

      # Set Size Setup
      @window_width = @window.width()
      @window_height = @window.height()

      # Visible @Size
      @visible_width = @window_width
      @visible_height = @window_height

      # @Parent Size
      @parent_width = @parent.width()
      @parent_height = @parent.height()

      # Entire Element Width
      @element_width = @parent_width
      @element_height = @element_width / @settings.width * @settings.height

      # Setup Layers
      @setup_layers()

      # Setup Videos
      @setup_videos()

      # Bind Window Resize
      @bind_resize()

      # Prevent Image Dragging
      @prevent_dragging()  if @settings.prevent_dragging is true

      # Perform Initial Load
      @load =>
        # Enable features
        @enable_pause_on_hover()  if @settings.pause_on_hover is true
        @enable_touch()  if @settings.touch is true
        @enable_mousewheel()  if @settings.mousewheel is true
        @enable_keyboard()  if @settings.keyboard is true

        # Scaling value is required for some layouts to set content in the right
        # position before sliding starts
        @scale_content()

        # Layout setup
        @setup()

        # Add feature elements
        @add_progress_bar()  if @settings.progress is true
        @add_thumbnails()  if @settings.thumbnails is true
        @add_scroller()  if @settings.scroller is true
        @add_pagination()  if @settings.pagination is true
        @add_controls()  if @settings.controls is true

        # Setup layout and content
        @setup_scaling() if @settings.content_scaling
        @setup_content()
        @setup_layout()

        # Split into grid
        @setup_grid()

        # Bind Window Scroll
        @bind_scroll()

        # Enable scrolling events
        @enable_content_parallax() if @settings.content_parallax
        @enable_parallax()

        # Hide the loader element
        @hide_loader()

        # Start the slider after loader is hidden
        setTimeout (=>
          @slide 0
        ), 500

        # Trigger on load event
        @element.trigger "slidea.load"

        return

      if @debug
        console.log @cache

      return

    # Load function to imagesLoaded images and cache slide animations
    #
    @load = (callback) ->
      i = 0
      @slides.each (index, el) =>
        $slide = $(el)

        # Preload Image
        $slide.imagesLoaded().always =>
          $background = $('.slidea-background', $slide)
          $layers = $('.slidea-layer', $slide)
          objects = $('.slidea-object', $slide)

          # Cache initialization
          @cache[index] = {}
          @cache[index].layer = {}
          @cache[index].object = {}

          # Cache background by setting a 0 index, used for easier animation
          # method in layout
          @cache[index].background =
            0: @get_data($slide, $background, 'background', @settings.delay)

          # Layer animation values based on the background transitions
          layer_delay = @cache[index].background[0].delay - (@cache[index].background[0].animation[0].duration / 2)
          layer_start = @cache[index].background[0].animation[@cache[index].background[0].start].duration

          # Cache layers by determining whether they are images or object layers
          $layers.each (layer_index, layer) =>
            $layer = $(layer)

            image = null
            if $layer.is('img')
              image = $(layer)

            @cache[index].layer[layer_index] = @get_data($layer, image, 'layer', layer_delay, layer_start)
            return

          # Cache objects
          objects.each (object_index, object) =>
            object = $(object)
            @cache[index].object[object_index] = @get_data(object, null, 'object', layer_delay, layer_start)
            return

          # Cache Thumbnail
          thumbnail = $slide.attr('data-slidea-thumbnail')
          if thumbnail
            @cache[index].thumbnail = thumbnail
          else
            @cache[index].thumbnail = $background.attr('src')

          # If all slides are loaded, call the actual setup function
          i++
          if i == @slides_length
            callback.call()
          return
        return
      return

    # Get element animation data based on its type
    #
    # @param object [Object] Current data gathering target
    # @param image [Object] Image target from which we gather layer sizes
    # @param type [String] Target type identifier
    # @param delay [Fixnum] Element default on screen display time
    # @param default_start [Fixnum] Element animation default start time
    #
    @get_data = (object, image, type, default_delay, default_start) ->
      string = ''

      time_stack = []
      animation_stack = {}

      i = 0

      # Set Element
      data = {}
      data.type = type
      data.animation = {}

      # Get Base Image Size
      if image != null and image.get(0)
        data.width = image.get(0).naturalWidth
        if !data.width?
          data.width = image.get(0).width
          if !data.width?
            data.width = image.width()
            if !data.width?
              data.width = @settings.width

        data.height = image.get(0).naturalHeight
        if !data.height?
          data.height = image.get(0).height
          if !data.height?
            data.height = image.height()
            if !data.height?
              data.height = @settings.height
      else
        data.width = 'auto'
        data.height = 'auto'

      # Get Layer Settings
      if type is 'layer'
        data.position = {}

        if object.attr('data-slidea-top')?
          data.position.top = parseFloat(object.attr('data-slidea-top'))
        else if object.attr('data-slidea-bottom')?
          data.position.bottom = parseFloat(object.attr('data-slidea-bottom'))
        else
          data.position.top = 0

        if object.attr('data-slidea-left')?
          data.position.left = parseFloat(object.attr('data-slidea-left'))
        else if object.attr('data-slidea-right')?
          data.position.right = parseFloat(object.attr('data-slidea-right'))
        else
          data.position.left = 0

        if object.attr('data-slidea-width')?
          data.width = parseFloat(object.attr('data-slidea-width'))
        if object.attr('data-slidea-height')?
          data.height = parseFloat(object.attr('data-slidea-height'))

      parallax_data = object.attr('data-slidea-parallax')
      data.parallax_data = {}
      if parallax_data?
        if parallax_data is 'false'
          data.parallax = false
        else
          data.parallax = true
          data.parallax_data.translate_y = parseFloat parallax_data
      else
        data.parallax = @settings.parallax
        data.parallax_data = @settings.parallax_data if @settings.parallax

      # Starting time for layer animation
      start = if object.attr('data-slidea-start')?
        parseInt(object.attr('data-slidea-start'), 10)
      else
        if type == 'background' then 0 else default_start

      data.start = start

      # Get Initial State
      initial_state = object.attr('data-slidea')
      initial_state = object.attr('data-slidea-initial') unless initial_state?

      if initial_state?
        starting_animation = initial_state
      else if type == 'background'
        starting_animation = @settings.animation.in
      else
        starting_animation = ''

      # This sets the initial state of our animated object
      # The entering animation will be set as css and will
      # transition to the default state
      animation_stack[start] = starting_animation
      time_stack[i++] = start

      # Set animation in override to set a different beginning state
      # other than the default one
      initial_animation_override = if object.attr('data-slidea-in')?
        @animus.get object.attr('data-slidea-in')
      else
        false

      # Get Animation Timeline
      timeline = object.data()
      $.each timeline, (key, value) ->
        time = undefined
        # Check if data key matches animation
        if (time = key.match(/slideaAt([0-9]+)/)) != null
          at_time = parseInt(time[1], 10)

          # Set value animation at time
          animation_stack[at_time] = value

          # Add time to time stack
          time_stack[i++] = at_time

        return

      # Set ending time as default delay or when last animation ends
      last_time = 0

      # The time stack is needed to maintain the order of
      # the object animations since JSON objects aren't ordered
      time_stack.sort()
      $.each time_stack, (key, value) =>
        data.animation[time_stack[key]] = @animus.get animation_stack[time_stack[key]]
        last_time = value if value > last_time
        return

      # Set grid initial status as disabled for now, might be possible to add
      # layers in the future
      data.grid =
        enabled: false

      # For backgrounds, we allow splitting images into tiles using set rows and
      # columns.
      if type is 'background'
        rows = object.attr('data-slidea-grid-rows')
        data.grid.rows = if rows?
          parseInt rows, 10
        else
          @settings.grid.rows

        columns = object.attr('data-slidea-grid-columns')
        data.grid.columns = if columns?
          parseInt columns, 10
        else
          @settings.grid.columns

        stagger = object.attr('data-slidea-grid-stagger')
        data.grid.stagger = if stagger?
          parseInt stagger, 10
        else
          @settings.grid.stagger

        if @settings.grid.columns > 1 or @settings.grid.rows > 1
          data.grid.enabled = true
          object.addClass 'slidea-grid-slide'


      # Get display time
      delay = object.attr('data-slidea-delay')
      if delay?
        data.delay = parseInt(delay, 10) + parseInt(data.animation[start].duration, 10)
      else if @settings.autoplay is false
        data.delay = 99999
      else
        data.delay = parseInt(default_delay, 10) + parseInt(data.animation[start].duration, 10)

      # Get Exit Animation
      out_animation = object.attr('data-slidea-out')
      out_animation = if out_animation?
        out_animation
      else
        if type == 'background'
          @settings.animation.out
        else
          ''

      # Set ending animation unless we have a null string
      if out_animation != ''
        end_time = if type == 'background'
          if data.delay > last_time + data.animation[last_time].duration
            data.delay
          else
            last_time + data.animation[last_time].duration
        else
          delay

        data.animation[end_time] = @animus.get out_animation

      # Set reset state by getting all the animation variables
      # and setting them to the default values
      if $.type(data.animation[start].state) isnt 'string'
        # Fade elements in, as they are faded out by default
        data.animation[start].state.opacity = 0 unless 'opacity' of data.animation[start].state

        # Get animation with resets, meaning besides initial values, we pass through all other
        # data animations and set default values for them as well
        data.animation[start].state = @animus.reset data.animation[start].state, data, true

        # Set initial animation data
        data.default_state = @animus.reset initial_animation_override.state, data, true
        data.initial_state = data.animation[start].state

        # Set initial animation as a forcefed animation, meaning we use the
        # initial state as final state, and we override the default state, if applicable
        data.animation[start].state = @animus.forcefeed data.animation[start].state, initial_animation_override.state

      # Set looping timeout
      data.loop = if object.attr('data-slidea-loop')?
        last_time + data.animation[last_time].duration + 1
      else
        false

      return data


    # Slide wrapper function for calling static slider method to move
    # the carousel to the next slide
    #
    @slide = (i) ->
      from = @current
      next = if i + 1 > @slides_length - 1 then 0 else i + 1
      prev = if i - 1 < 0 then @slides_length - 1 else i - 1

      # Index Logic
      if i == @current
        return
      if i > @slides_length - 1
        i = 0
      if i < 0
        i = @slides_length - 1

      # Set Active
      unless @current == -1
        $('.previous', @element).removeClass 'previous'
        @active.removeClass('active').addClass 'previous'
      @active = @slides.eq(i)
      @active.removeClass('next').addClass 'active'
      unless i == @slides_length - 1
        @slides.eq(i).addClass 'next'

      # Clear Timeouts
      if @current != -1
        @clear_timeouts @current
      @clear_timeouts i

      # Layout specific slide method
      @layout[@settings.layout].slide.call this, i, @current

      # Slidea specific slide method
      @animate i, @current

      # Set Current
      @current = i

      # Go to the next slide after the slide specific delay
      if @settings.autoplay is true
        @timer.start = new Date
        @timer.remaining = @cache[i].background[0].delay
        clearTimeout @timer.clock
        @timer.clock = setTimeout((=>
          if @settings.loop isnt true and i + 1 == @slides_length - 1
            return
          @slide i + 1
          return
        ), @timer.remaining)

      # Animate Progress Bar
      if @settings.progress is true
        @progress.bar.velocity('stop').velocity({ width: '0%' }, 0).velocity { width: '100%' }, @timer.remaining

      # Set Control Thumbnails
      if @settings.controls is true and @settings.controls_thumbnail is true
        @prev_thumbnail.attr 'src', @cache[prev].thumbnail
        @next_thumbnail.attr 'src', @cache[next].thumbnail

      # Set Pagination Active
      if @settings.pagination
        @pagination.filter('.active').removeClass 'active'
        @pagination.eq(i).addClass 'active'

      # Set Thumbnails Active
      if @settings.thumbnails
        @thumbnails.elements.filter('.active').removeClass 'active'
        @thumbnails.elements.eq(i).addClass 'active'

      # Handle Videos
      @handle_videos from, i
      @element.trigger 'slidea.change', [
        prev
        @current
        next
        @slides_length
        @active
      ]
      return

    # Apply animations for all slider elements. Run previous slide out animation
    # and set time required to crossfade slides
    #
    # @param i [Fixnum] Current slide index
    # @param prev [Fixnum] Previous slide index
    #
    @animate = (i, prev) =>
      # Set main active slide
      $active = @slides.eq(i)

      # Staggering timeout when using grid layouts
      prev_stagger = 0

      # Clear main animation timeout to prevent bad behaviour for unusual
      # request counts (click spamming)
      clearTimeout @animate_timeout

      # Run animateOut animation for previous slide
      if prev != -1 and @settings.layout_settings.animate_background isnt false
        $prev = @slides.eq(prev)
        $prev_layers = $ '.slidea-layer-wrapper', $prev
        $prev_objects = $ '.slidea-object', $prev
        previous_delay = @cache[prev].background[0].delay

        # Fade content out together with the slide for a smooth transition
        $('.slidea-content-wrapper, .slidea-layer', $prev).velocity
          opacity: 0
        , @cache[prev].background[0].animation[previous_delay].duration

        # Animate background out if we have an out animation set for the previous slide
        unless $.isEmptyObject @cache[prev].background[0].animation[previous_delay].state
          prev_target = $prev

          # Set animation options
          options =
            duration: @cache[prev].background[0].animation[previous_delay].duration
            easing: @cache[prev].background[0].animation[previous_delay].easing
            display: null
            complete: =>
              # When we're done with the previous slide, we stop all animations
              # to prevent lagging and other bad behaviour
              $prev.velocity('stop')
              $prev_layers.velocity('stop')
              $prev_objects.velocity('stop')
              #$('.slidea-grid-cell', $prev).velocity('stop') if @cache[prev].background[0].grid.enabled
              return

          # When a grid is set, animate the grid elements of the previous slide
          if @cache[prev].background[0].grid.enabled
            options.stagger = @cache[prev].background[0].grid.stagger

            # Set cells as animation target when grid is enabled
            prev_target = $('.slidea-grid-cell', $prev)

            # Fade the background out and fade the grid in, also reset
            # state for active grid
            $.Velocity.hook $('.slidea-grid', $active), 'opacity', 1
            $.Velocity.hook $('.slidea-grid-cell', $active), 'opacity', 0
            $.Velocity.hook $('.slidea-grid', $prev), 'opacity', 1
            $('.slidea-background-main', $prev).velocity opacity: 0, 20

            # When we have an UI animation, override display settings
            if $.type(@cache[prev].background[0].animation[previous_delay].state) is 'string'
              prev_stagger += $('.slidea-grid-cell', $prev).length * @cache[prev].background[0].grid.stagger

          # After fading the grid in if it exists, animate the previous slide
          prev_target.velocity @cache[prev].background[0].animation[previous_delay].state, options

      # Add the staggering (if it exists) to the next animation timeout
      if prev is -1
        timeout = prev_stagger
      else
        timeout = @cache[prev].background[0].animation[previous_delay].duration + prev_stagger

      # If we want the slides to play one after another, the overlap parameter
      # should be 1, otherwise 0. Everything in between will cause partial
      # overlapping
      timeout *=  @settings.overlap

      # Runs active slide animation timeline for background, layers and objects
      #
      # Set a timeout to overlap, depending on slider settings
      $.Velocity.hook $('.slidea-background-main', $active), 'opacity', 0
      @animate_timeout = setTimeout =>
        $layers = $ '.slidea-layer-wrapper', $active
        $objects = $ '.slidea-object', $active

        # Fade content in, together with the slide for a smooth transition
        $('.slidea-content-wrapper, .slidea-layer', $active).velocity
          opacity: 1
        , @cache[i].background[0].animation[@cache[i].background[0].start].duration

        # Background animations
        if @settings.layout_settings.animate_background isnt false
          if @cache[i].background[0].grid.enabled
            @run_grid_animation i, $('.slidea-grid-cell', $active), 'background', 0, false
          else
            @run_animation i, $active, 'background', 0, false

        # Layer animations
        $layers.each (layer_index, layer) =>
          @run_animation i, $(layer), 'layer', layer_index, false

        # Object animation
        $objects.each (object_index, object) =>
          @run_animation i, $(object), 'object', object_index, false

        return
      , timeout
      return

    # Grid animation wrapper to run a callback after grid animation ends
    #
    @run_grid_animation = (i, $element, context, context_index, in_loop)  =>
      $active = @slides.eq(i)
      @run_animation i, $element, context, context_index, in_loop, =>
        $.Velocity.hook $('.slidea-background-main', $active), 'opacity', 1
        $('.slidea-grid', $active).velocity opacity: 0, 20
        return
      return

    # Runs animation for current slide, generalized for background, layers and
    # objects use
    #
    # @param i [Fixnum] Index of current cached element
    # @param $element [Object] Element on which animation is applied
    # @param context [String] Current animation cache accessor
    # @param context_index [Fixnum] Current animation cache accessor index
    #
    @run_animation = (i, $element, context, context_index, in_loop, callback) =>
      # Set initial animation position for active slide
      current_delay = @cache[i].background[0].delay

      # Set initial state for element
      unless in_loop || !@cache[i][context][context_index].initial_state?
        $element.velocity @cache[i][context][context_index].initial_state,
          duration: 1

      # Run each background animation as long as the animation index isn't greater
      # than the slide's preview delay
      $.each @cache[i][context][context_index].animation, (index) =>
        if index >= current_delay or isNaN(index) or $.isEmptyObject(@cache[i][context][context_index].animation[index].state)
          return

        # Set timeout for each animation, with it's state
        @cache[i][context][context_index].animation[index].timeline = setTimeout((=>
          options =
            duration: @cache[i][context][context_index].animation[index].duration
            easing: @cache[i][context][context_index].animation[index].easing
            display: null

          if @cache[i][context][context_index].grid.enabled
            options.stagger = @cache[i][context][context_index].grid.stagger

            if callback? and parseInt(index) == @cache[i][context][context_index].start
              options.complete = callback

          $element.velocity @cache[i][context][context_index].animation[index].state, options
          return
        ), index)

        return

      if @cache[i][context][context_index].loop
        @cache[i][context][context_index].loop_timeout = setTimeout =>
          @run_animation i, $element, context, context_index, true
        , @cache[i][context][context_index].loop

      return


    # Setup wrapper function for calling static @method
    #
    @setup = =>
      # Create Layout
      @layout = {}
      $.each $.slidea.layouts, (index, value) =>
        @layout[index] = new value
        return

      if @layout.length == 0
        console.error "Slidea couldn't find any valid layouts."

      # Init Layout
      @layout[@settings.layout].init.call this

      return

    # Setup layout wrapper
    #
    @setup_layout = =>
      # Setup Layout
      @layout[@settings.layout].setup.call this

      return

    # Set default animation parameters for Slidea animation objects
    # and create animus model
    #
    @init_animus = =>
      override =
        duration: @settings.animation.duration
        easing: @settings.animation.easing

      # Animus
      @animus = new $.animus(override)
      return

    # Adds a CSS3 Animated loader to the slider
    #
    @add_loader = =>
      if $(".slidea-loader-wrapper", @element).length is 0
        html = ""
        html += '<div class="slidea-loader-wrapper">'
        html += '<div class="slidea-loader">'
        html += '<div class="slidea-loader-inner">'
        html += '<div class="slidea-loader-tile"></div>'
        html += '<div class="slidea-loader-tile"></div>'
        html += '<div class="slidea-loader-tile"></div>'
        html += '<div class="slidea-loader-tile"></div>'
        html += '<div class="slidea-loader-tile"></div>'
        html += '</div>'
        html += '</div>'
        html += '<div class="slidea-loader-text">'
        html += '<h5 class="slidea-loader-title font-normal">'
        html += 'SLIDEA'
        html += '</h5>'
        html += '<h6 class="slidea-loader-subtitle font-thin">'
        html += 'A Smarter Slider Plugin'
        html += '</h6>'
        html += '</div>'
        html += '</div>'
        @element.prepend html
      return

    # Hides CSS3 Animated loader to the slider
    #
    @hide_loader = =>
      $(".slidea-loader-wrapper", @element).velocity
        scale: 1.5
        opacity: 0
      ,
        display: "none"
      ,
        duration: 600
      return

    # Check if @has data-slidea-settings which override default init settings
    #
    @set_data_settings = =>
      @settings.width = @element.attr("data-slidea-width")  if @element.attr("data-slidea-width")?
      @settings.height = @element.attr("data-slidea-height")  if @element.attr("data-slidea-height")?
      @settings.animation.initial = @element.attr("data-slidea-initial")  if @element.attr("data-slidea-animation-initial")?
      @settings.animation.out = @element.attr("data-slidea-out")  if @element.attr("data-slidea-animation-out")?
      @settings.animation.duration = @element.attr("data-slidea-duration")  if @element.attr("data-slidea-duration")?
      @settings.animation.easing = @element.attr("data-slidea-easing")  if @element.attr("data-slidea-easing")?
      @settings.delay = @element.attr("data-slidea-delay")  if @element.attr("data-slidea-delay")?
      @settings.layout = @element.attr("data-slidea-layout")  if @element.attr("data-slidea-layout")?
      return

    # Add the actual classes to the Slidea selector classes
    #
    @add_classes = =>
      $(@settings.selector.slide, @element).addClass "slidea-slide"
      $(@settings.selector.content, @element).addClass "slidea-content"
      $(@settings.selector.background, @element).addClass "slidea-background"
      $(@settings.selector.video_background, @element).addClass "slidea-video-background"
      $(@settings.selector.video, @element).addClass "slidea-video"
      $(@settings.selector.video_cover, @element).addClass "slidea-video-cover"
      $(@settings.selector.layer, @element).addClass "slidea-layer"
      $(@settings.selector.object, @element).addClass "slidea-object"
      $(@settings.selector.next, @element).addClass "slidea-next"
      $(@settings.selector.prev, @element).addClass "slidea-prev"
      $(@settings.selector.pagination, @element).addClass "slidea-pagination"

      return

    # Wrap the slides with a .slidea-inner class for layout flexibility
    #
    @wrap_objects = =>
      $('.slidea-slide', @element).each (i, slide) =>
        $(@settings.selector.background + ', ' + @settings.selector.layer, $(slide)).wrapAll "<div class=\"slidea-canvas\"></div>"
      .wrapAll "<div class=\"slidea-outer\"><div class=\"slidea-inner\"></div></div>"
      $('.slidea-content', @element).wrap '<div class="slidea-content-wrapper"></div>'
      $('.slidea-background', @element).wrap '<div class="slidea-background-wrapper"></div>'
      $('.slidea-layer', @element).wrap '<div class="slidea-layer-wrapper"></div>'
      return

    # Set the z-index of each of the @layers
    #
    @setup_layers = =>
      @slides.each (si, slide) =>
        layers = $(".slidea-layer-wrapper", $(slide))
        layer_count = layers.length
        layers.each (li, layer) =>
          $(layer).css "z-index", @settings.layer_index + layer_count - li
      return

    # Setup video events at slide start for HTML5, YouTube and Vimeo videos
    #
    @setup_videos = =>
      delay = 500
      interval = undefined
      i = 0
      tries = 10

      $("video.slidea-video", @element).attr "data-slidea-video-type", "html5"
      $("iframe[data-slidea-src*=\"youtube.com\"].slidea-video", @element).attr "data-slidea-video-type", "youtube"
      $("iframe[data-slidea-src*=\"vimeo.com\"].slidea-video", @element).attr "data-slidea-video-type", "vimeo"
      $(".slidea-video", @element).each (i, el) ->
        $video = $(el)
        volume = $video.attr("data-slidea-volume")
        controls = ($video.attr("data-slidea-controls") is "true")
        pause.slider= ($video.attr("data-slidea-pause-slider") is "true")
        random_id = "slidea-video-" + Math.floor((Math.random() * 1000) + 1)
        volume = (if isNaN(volume) then 0 else volume)
        $video.attr "id", random_id  unless $video.attr("id")?
        id = $video.attr("id")
        src = $video.attr("data-slidea-src")

        # HTML5
        if $video.attr("data-slidea-video-type") is "html5"
          $video.get(0).volume = volume
          $video.attr "controls", "controls"  if controls is true
          if @settings.autoplay is true and pause.slider is true
            $video.on "play", =>
              @pause_timer()

            $video.on "pause ended", =>
              @unpause_timer()


        # YouTube
        if $video.attr("data-slidea-video-type") is "youtube"
          video_id = undefined
          separator = undefined

          if src.indexOf("enablejsapi=1") is -1
            if src.indexOf("?") is -1
              $video.attr "src", src + "?enablejsapi=1"
            else
              $video.attr "src", src + "&enablejsapi=1"
            src = $video.attr("src")

          if src.indexOf("playerapiid=") is -1
            if src.indexOf("?") is -1
              $video.attr "src", src + "?playerapiid=" + id
            else
              $video.attr "src", src + "&playerapiid=" + id
            src = $video.attr("src")

          unless src.indexOf("embed") is "-1"
            video_id = src.split("/")
            video_id = video_id[video_id.length - 1]
            separator = video_id.indexOf("?")
            video_id = video_id.substring(0, separator)  unless separator is -1
          else
            video_id = src.split("v=")[1]
            separator = video_id.indexOf("&")
            video_id = video_id.substring(0, separator)  unless separator is -1

          $video.load =>
            @youtube_player[id] = new YT.Player(id,
              height: "720"
              width: "1280"
              video_id: video_id
              events:
                onStateChange: (e) =>
                  @pause_timer()  if e.data is 1
                  @unpause_timer()  if e.data is 2 or e.data is 0
            )

            i = 0
            interval = setInterval(=>
              i++
              if i is tries
                clearInterval interval
              else if not @youtube_player[id]? or typeof @youtube_player[id].setVolume isnt "function"
                return
              else
                clearInterval interval
              @youtube_player[id].setVolume volume
            , delay)


        # Vimeo
        if $video.attr("data-slidea-video-type") is "vimeo"
          if src.indexOf("api=1") is -1
            if src.indexOf("?") is -1
              $video.attr "src", src + "?api=1"
            else
              $video.attr "src", src + "&api=1"
            src = $video.attr("src")
          if src.indexOf("player_id=") is -1
            if src.indexOf("?") is -1
              $video.attr "src", src + "?player_id=" + id
            else
              $video.attr "src", src + "&player_id=" + id
            src = $video.attr("src")
          $video.load =>
            @vimeo_player[id] = $f(id)
            @vimeo_player[id].addEvent "ready", =>
              $video.attr "data-slidea-ready", "true"
              @vimeo_player[id].api "setVolume", volume
              if @settings.autoplay is true and pause.slider is true
                @vimeo_player[id].addEvent "play", pause_timer
                @vimeo_player[id].addEvent "pause", unpause_timer
                @vimeo_player[id].addEvent "finish", unpause_timer

      $(".slidea-video-cover", @element).each (i, el) =>
        $cover = $(el)
        $parent = $cover.parent()
        $video = $(".slidea-video", $parent)
        type = $video.attr("data-slidea-video-type")
        id = $video.attr("id")
        switch type
          when "html5"
            $cover.on "click", =>
              $video.get(0).play()
              $cover.velocity "fadeOut"

          when "youtube"
            $cover.on "click", =>
              @youtube_player[id].playVideo()
              $cover.velocity "fadeOut"

          when "vimeo"
            $cover.on "click", =>
              @vimeo_player[id].api "play"
              $cover.velocity "fadeOut"

      return

    # Handle autoplay timeouts using a timeout timeline
    #
    video_timeline = {}

    # Handle video events at slide start for HTML5, YouTube and Vimeo videos
    #
    @handle_videos = (previous, current) ->
      $previous = @slides.eq(previous)
      $current = @slides.eq(current)

      # Pause or stop videos from previous slide
      if previous != -1
        $('.slidea-video', $previous).each =>
          $video = $(` this `)
          id = $video.attr('id')
          reset = $video.attr('data-slidea-reset') == 'true'
          clearTimeout video_timeline[id]

          # HTML5
          if $video.attr('data-slidea-video-type') == 'html5'
            $video.get(0).pause()
            if reset
              setTimeout (=>
                $video.get(0).current_time = 0
                return
              ), @cache[current].background[0].animation[0].duration

          # Youtube
          if $video.attr('data-slidea-video-type') == 'youtube'
            @youtube_player[id].pauseVideo()
            if reset
              setTimeout (=>
                @youtube_player[id].stopVideo()
                return
              ), @cache[current].background[0].animation[0].duration

          # Vimeo
          if $video.attr('data-slidea-video-type') == 'vimeo'
            @vimeo_player[id].api 'pause'
            if reset
              setTimeout (=>
                @vimeo_player[id].api 'unload'
                return
              ), @cache[current].background[0].animation[0].duration
          return

      # Play videos from current slide
      $('.slidea-video', $current).each =>
        $video = $(` this `)
        id = $video.attr('id')
        delay = 500
        interval = undefined
        i = 0
        tries = 10
        autoplay = $video.attr('data-slidea-autoplay') == 'true'
        autoplay_time = if $video.attr('data-slidea-autoplay-time')? then parseInt($video.attr('data-slidea-autoplay-time'), 10) else 100
        pause_slider = $video.attr('data-slidea-pause-slider') == 'true'

        # HTML5
        if $video.attr('data-slidea-video-type') == 'html5'
          if autoplay == true
            video_timeline[id] = setTimeout((->
              $video.get(0).play()
              return
            ), autoplay_time)

        # Youtube
        if $video.attr('data-slidea-video-type') == 'youtube'
          if autoplay == true
            i = 0
            interval = setInterval((=>
              i++
              if i == tries
                clearInterval interval
              else if !defined(@youtube_player[id]) or typeof @youtube_player[id].playVideo != 'function'
                return
              else
                clearInterval interval
              video_timeline[id] = setTimeout((=>
                @youtube_player[id].playVideo()
                return
              ), autoplay_time)
              return
            ), delay)

        # Vimeo
        if $video.attr('data-slidea-video-type') == 'vimeo'
          if autoplay == true
            i = 0
            interval = setInterval((=>
              i++
              if i == tries
                clearInterval interval
              else if !$video.attr('data-slidea-ready')? or typeof @vimeo_player[id].api != 'function'
                return
              else
                clearInterval interval
              video_timeline[id] = setTimeout((->
                Froogaloop(id).api 'play'
                return
              ), autoplay_time)
              return
            ), delay)
        return
      return


    # Binds the slider window resize event to cache current window
    # width and height and to set the layout up
    #
    @bind_resize = =>
      @window.resize =>
        @window_width = @window.width()
        @window_height = @window.height()

        @parent_width = @parent.width()
        @parent_height = @parent.height()

        @element_width = @parent_width
        @element_height = @element_width / @settings.width * @settings.height

        @setup_content()
        @setup_layout()

        @resize_grid()
        @resize_thumbnails()  if @settings.thumbnails is true

        return
      return

    # Bind the window scroll event to fade content on scroll down
    #
    @bind_scroll = =>
      #@window.on 'scroll', =>
      #  return
      return

    # Scroll fade a the slidea content if the current scroll is greater than
    # the slider's top position
    #
    # @param position [Fixnum] Current scrolling position
    #
    @enable_content_parallax = (position) ->
      $('.slidea-content', @element).each (index, element) =>
        settings =
          overflow: true
          reset: true
          source: $(element).parent()

        $(element).visuallax $.extend(settings, @settings.content_parallax_data)
        return

      return

    # Parallax images when in fluid layout mode
    #
    # @param position [Fixnum] Current scrolling position
    #
    @enable_parallax = (position) ->
      @slides.each (index, slide) =>
        settings =
          parent: @inner
          source: $(slide)
          overflow: true
          reset: true

        if @cache[index].background[0].parallax
          $('.slidea-background-wrapper', $(slide)).visuallax $.extend({}, settings, @settings.parallax_data)

          if @settings.parallax_layers
            $('.slidea-layer', $(slide)).each (li, layer) =>
              if @cache[index].layer[li].parallax
                settings =
                  translate_y: @cache[index].layer[li].parallax
                  parent: @inner
                  source: $(slide)
                  overflow: true
                  reset: true

                $(layer).visuallax $.extend({}, settings, @settings.parallax_data)
              return
        return
      return


    # Add progress bar to the @container
    #
    @add_progress_bar = =>
      position = (if @settings.progress_position is "top" then "slidea-progress-top" else "slidea-progress-bottom")

      html = ""
      html += "<div class=\"slidea-progress " + position + " " + @settings.progress_class + "\">"
      html += "<div class=\"slidea-progress-bar\">"
      html += "</div>"
      html += "</div>"
      @element.prepend html

      @progress = {}
      @progress.element = $(".slidea-progress", @element)
      @progress.bar = $(".slidea-progress-bar", @element)

      return

    # Prevent image dragging
    #
    @prevent_dragging = =>
      $("img", @element).on "dragstart", (event) ->
        event.preventDefault()
        return
      return

    # Add pagination bullets to the slider
    #
    @add_pagination = =>
      position = (if @settings.pagination_position is "inside" then "slidea-pagination-inside" else "slidea-pagination-outside")

      # Don't add pagination if we have only one slide
      return if @slides_length == 1

      html = ""
      html += "<div class=\"slidea-pagination " + position + " " + @settings.pagination_class + "\">"
      i = 0
      while i < @slides_length
        html += "<div class=\"slidea-pagination-bullet\"></div>"
        i++
      html += "</div>"
      $pagination = $(html)

      if @settings.pagination_position is "inside"
        @element.prepend $pagination
      else
        @element.after $pagination

      @pagination = $(".slidea-pagination-bullet", $pagination)
      @pagination.each (i, el) =>
        $bullet = $(el)
        $bullet.on "click", =>
          @pagination.filter(".active").removeClass "active"
          $bullet.addClass "active"
          @slide i
          return
        return
      return

    # Add scroller item to @and bind click action
    #
    @add_scroller = =>
      scroller = "<div class=\"slidea-scroller-wrapper slidea-scroller-" + @settings.scroller_position + "\">"
      scroller += @settings.scroller_markup
      scroller += "</div>"

      @scroller = $ scroller
      @element.prepend @scroller

      @scroller.on "click", =>
        $("html").velocity "scroll",
          offset: @element.height()
          mobileHA: true
          duration: 1000
        return
      return


    # Add thumbnails underneath our slider
    #
    @add_thumbnails = =>
      thumbs_count = @settings.thumbnails_visible.desktop
      if @window_width < 768
        thumbs_count = @settings.thumbnails_visible.phone
      else if @window_width < 992
        thumbs_count = @settings.thumbnails_visible.tablet
      else
        thumbs_count = @settings.thumbnails_visible.laptop  if @window_width < 1200

      width = @parent_width / thumbs_count
      inner_width = width * @slides_length

      html = ""
      html += "<div class=\"slidea-thumbnails " + @settings.thumbnails_class + "\">"
      html += "<div class=\"slidea-thumbnails-inner\" style=\"width: " + inner_width + "px;\">"
      $.each @cache, (index, item) ->
        html += "<div class=\"slidea-thumbnail-wrapper\" style=\"width: " + width + "px;\">"
        html += "<img class=\"slidea-thumbnail\" src=\"" + item.thumbnail + "\" alt=\"Slide " + index + "\" />"
        html += "</div>"
      html += "</div>"
      html += "</div>"

      @thumbnails = {}
      @thumbnails.wrapper = $(html)

      if @settings.thumbnails_position is "before"
        @element.before @thumbnails.wrapper
      else
        @element.after @thumbnails.wrapper

      @thumbnails.inner = $(".slidea-thumbnails-inner", @thumbnails.wrapper)
      @thumbnails.elements = $(".slidea-thumbnail-wrapper", @thumbnails.wrapper)

      @thumbnails.elements.each (i, el) =>
        $thumbnail = $(el)
        $thumbnail.on "click", =>
          @thumbnails.elements.filter(".active").removeClass "active"
          $thumbnail.addClass "active"
          @slide i
          return
        return

      $("img", @thumbnails.elements.eq(0)).each (i, el) =>
        $(el).load =>
          height = $(el).height()
          @thumbnails.inner.height height

      $("img", @thumbnails.elements).on "dragstart", (event) ->
        event.preventDefault()


      ##@TODO
      if @settings.touch is true
        translate_thumbnails = 0
        @thumbnails.elements.hammer().on "pan", (ev) =>
          width = @thumbnails.inner.width()
          parent_width = @thumbnails.wrapper.width()
          translate_thumbnails -= ev["gesture"]["velocityX"] * 7
          if translate_thumbnails > 0
            translate_thumbnails = 0
          else
            translate_thumbnails = parent_width - width  if translate_thumbnails < parent_width - width

          if translate_thumbnails <= 0 and translate_thumbnails > parent_width - width
            @thumbnails.inner.velocity
              translateZ: 0
              translateX: translate_thumbnails
            , 1
          ev.preventDefault()

      return

    # Resize thumbnails when window resize happens
    #
    @resize_thumbnails = =>
      thumbs_count = @settings.thumbnails_visible.desktop

      if @window_width < 768
        thumbs_count = @settings.thumbnails_visible.phone
      else if @window_width < 992
        thumbs_count = @settings.thumbnails_visible.tablet
      else
        thumbs_count = @settings.thumbnails_visible.laptop  if @window_width < 1200

      width = @parent_width / thumbs_count
      inner_width = width * @slides_length
      @thumbnails.inner.width inner_width
      @thumbnails.elements.width width

      height = undefined
      height = $("img", @thumbnails.elements.eq(0)).height()
      @thumbnails.inner.height height
      @thumbnails.inner.css
        "webkit-transform": "translate3d(0, 0, 0)"
        "moz-transform": "translate3d(0, 0, 0)"
        "ms-transform": "translate3d(0, 0, 0)"
        "o-transform": "translate3d(0, 0, 0)"
        transform: "translate3d(0, 0, 0)"
      , 0

      return



    # Get swipe direction and go to next or previous slide
    #
    @enable_touch = =>
      @touch_object = new Hammer @element[0]
      @touch_object.get('pan').set
        direction: Hammer.DIRECTION_HORIZONTAL
      @touch_object.on 'panleft panright', (event) =>
        if event.eventType is Hammer.INPUT_START
          @element.addClass 'slidea-dragging'

        else if event.eventType is Hammer.INPUT_END or event.eventType is Hammer.INPUT_CANCEL
          @element.removeClass 'slidea-dragging'

          if event.direction is Hammer.DIRECTION_LEFT
            @slide @current + 1
          else if event.direction is Hammer.DIRECTION_RIGHT
            @slide @current - 1

        return
      return

    # Get scrolling direction and go to next or previous slide
    #
    @enable_mousewheel = =>
      @element.mousewheel (event) =>
        if event.deltaY == -1
          @slide @current + 1
        if event.deltaY == 1
          @slide @current - 1
        if @settings.prevent_scrolling is true
          event.preventDefault()
        return
      return

    # Get arrow input and slide to next or previous slide
    #
    @enable_keyboard = =>
      $(document).keydown (e) =>
        switch e.which
          when 37 then @slide @current - 1
          when 39 then @slide @current + 1
          else return
      return

    # Enable next and previous buttons and bind the controls
    #
    @add_controls = =>
      prev_html = ''
      prev_html += '<a href="javascript:void(0);" class="slidea-control slidea-prev ' + @settings.controls_class + '">'
      prev_html += '<div class="slidea-control-inner">'
      if @settings.controls_thumbnail is true
        prev_html += '<div class="slidea-control-thumbnail">'
        prev_html += '<img src="" alt="Previous Slide" class="slidea-control-image"/>'
        prev_html += '</div>'
      prev_html += '<div class="slidea-control-text">'
      prev_html += @settings.controls_html.prev
      prev_html += '</div>'
      prev_html += '</div>'
      prev_html += '</a>'

      next_html = ''
      next_html += '<a href="javascript:void(0);" class="slidea-control slidea-next ' + @settings.controls_class + '">'
      next_html += '<div class="slidea-control-inner">'
      if @settings.controls_thumbnail is true
        next_html += '<div class="slidea-control-thumbnail">'
        next_html += '<img src="" alt="Next Slide" class="slidea-control-image"/>'
        next_html += '</div>'
      next_html += '<div class="slidea-control-text">'
      next_html += @settings.controls_html.next
      next_html += '</div>'
      next_html += '</div>'
      next_html += '</a>'

      @outer.append prev_html + next_html
      @prev_button = $('.slidea-prev', @element)
      @next_button = $('.slidea-next', @element)

      if @settings.controls_thumbnail is true
        @prev_thumbnail = $('.slidea-control-image', @prev_button)
        @next_thumbnail = $('.slidea-control-image', @next_button)

      @prev_button.on 'click', =>
        @slide @current - 1
        return
      @next_button.on 'click', =>
        @slide @current + 1
        return

      return

    # Get swipe direction and go to next or previous slide
    #
    @enable_pause_on_hover = =>
      @element.on 'mouseenter', =>
        @pause_timer()
        return
      @element.on 'mouseleave', =>
        @unpause_timer()
        return
      return

    # Pause autoplay when mouse is over @element
    #
    @pause_timer = =>
      current_time = new Date
      @timer.remaining = @timer.remaining - (current_time - (@timer.start))
      clearTimeout @timer.clock
      if @settings.progress is true
        @progress.bar.velocity 'stop'
      @element.trigger 'slidea.pause'
      return

    # Unpause timer when hovering over @element
    #
    @unpause_timer = =>
      next_slide = if @current == -1 then 1 else @current + 1

      @timer.start = new Date

      clearTimeout @timer.clock

      @timer.clock = setTimeout((=>
        @slide next_slide
        return
      ), @timer.remaining)

      if @settings.progress is true
        @progress.bar.velocity { width: '100%' }, @timer.remaining

      @element.trigger 'slidea.resume'

      return

    # Prevent image dragging on computers
    #
    @prevent_dragging = =>
      $('img', @element).on 'dragstart', (event) ->
        event.preventDefault()
        return
      return

    # Add progress bar to the @container
    #
    @add_progress_bar = =>
      position = if @settings.progress_position == 'top' then 'slidea-progress-top' else 'slidea-progress-bottom'
      html = ''
      html += '<div class="slidea-progress ' + position + ' ' + @settings.progress_class + '">'
      html += '<div class="slidea-progress-bar">'
      html += '</div>'
      html += '</div>'
      @element.prepend html
      @progress = {}
      @progress.element = $('.slidea-progress', @element)
      @progress.bar = $('.slidea-progress-bar', @element)
      return

    # Set the content width and responsive font size utility
    #
    @setup_content = =>
      $content = $('.slidea-content-wrapper', @element)

      # Responsive Content scaling
      if @settings.content_scaling is true
        @scale_content()
      else
        $content.width @outer.width()
        $content.height @outer.height()
      return

    # Set content width and height if content scaling is enabled
    #
    @setup_scaling = =>
      $content = $('.slidea-content-wrapper', @element)

      # Set reference content width, in relation with slide canvas default width
      @scaling_reference = if @settings.content_width
        @settings.content_width
      else
        @settings.width

      $content.width @scaling_reference
      $content.height @settings.width / @scaling_reference * @settings.height

      return

    # Calculate scaling values based on input settings
    #
    @scale_content = =>
      $content = $('.slidea-content-wrapper', @element)

      # Calculate scaling width based on scaling factor
      calculated_width = if @settings.content_scaling_factor is 1
        @outer.width()
      else
        # Set diference between element width and reference width
        difference = @outer.width() - @scaling_reference

        # Set calculated width based on a different scaling factor
        if difference > 0
          @scaling_reference + difference * @settings.content_scaling_factor
        else
          @scaling_reference - difference * @settings.content_scaling_factor

      # Calculate scaling value based on current width
      @scaling_value = calculated_width / @scaling_reference

      $.Velocity.hook $content, 'translateZ', '0px'
      $.Velocity.hook $content, 'transformOriginX', '0px'
      $.Velocity.hook $content, 'transformOriginY', '0px'
      $.Velocity.hook $content, 'scaleX', @scaling_value
      $.Velocity.hook $content, 'scaleY', @scaling_value

      return

    # Setup the slidea grid system
    #
    @setup_grid = =>
      $('.slidea-background-wrapper', @element).each (index, element) =>
        grid = @cache[index].background[0].grid

        if grid.enabled
          cell_count = grid.columns * grid.rows

          $(element).append $('<div class="slidea-grid"></div>')

          background = $('.slidea-background', $(element))
          for i in [0..cell_count - 1]
            background.clone().appendTo($('.slidea-grid', $(element))).wrap '<div class="slidea-grid-cell"></div>'

          background.addClass 'slidea-background-main'
        return

      # Set correct sizes for the grid system
      @resize_grid()
      return

    # If a grid is set, split the background images
    #
    @resize_grid = =>
      $('.slidea-background-wrapper', @element).each (index, element) =>
        grid = @cache[index].background[0].grid

        if grid.enabled
          main_width = @inner.width()
          main_height = @inner.height() 

          cell_width = main_width / grid.columns
          cell_height = main_height / grid.columns

          # Set cell width and height, percentage based
          cells = $('.slidea-grid-cell', $(element))
          cells.width(cell_width).height cell_height

          # Translate each grid and column background in order to maintain
          # a perfect ratio and resemble the original image
          for i in [0..grid.rows]
            for j in [0..grid.columns]
              this_cell = cells.eq(i * grid.columns + j)
              $.Velocity.hook this_cell, 'translateX', "#{cell_width * j}px"
              $.Velocity.hook this_cell, 'translateY', "#{cell_height * i}px"

              this_background = $('.slidea-background', this_cell)
              this_background.width(main_width).height main_height
              $.Velocity.hook this_background, 'translateX', "-#{cell_width * j}px"
              $.Velocity.hook this_background, 'translateY', "-#{cell_height * i}px"
        return
      return

    # Clears all the set timeouts for the chosen slide in order to stop all
    # programmed animations.
    #
    # @version2.0 Loop timeouts must also be cleared after every slide
    #
    @clear_timeouts = (i) ->
      $.each @cache[i].background[0].animation, (index) =>
        clearTimeout @cache[i].background[0].animation[index].timeline
        return
      if 'loop_timeout' of @cache[i].background[0]
        clearTimeout @cache[i].background[0].loop_timeout

      $.each @cache[i].layer, (index) =>
        $.each @cache[i].layer[index].animation, (animateIndex) =>
          clearTimeout @cache[i].layer[index].animation[animateIndex].timeline
          return
        if 'loop_timeout' of @cache[i].layer[index]
          clearTimeout @cache[i].layer[index].loop_timeout
        return

      $.each @cache[i].object, (index) =>
        $.each @cache[i].object[index].animation, (animateIndex) =>
          clearTimeout @cache[i].object[index].animation[animateIndex].timeline
          return
        if 'loop_timeout' of @cache[i].object[index]
          clearTimeout @cache[i].object[index].loop_timeout
        return

      return


    # Helper methods to slide to next slide or to the previous one
    #
    @next = =>
      @slide @current + 1
      return

    @prev = =>
      @slide @current - 1
      return

    # Helper methods to pause or unpause the slider
    #
    @pause = =>
      @pause_timer()
      return

    @resume = =>
      @unpause_timer()
      return

    @initialize()


  # Keeps all Slidea layout definitions to be instantiated when needed
  $.slidea.layouts = {}

  # Add a new Slidea layout at runtime
  $.slidea.add_layout = (name, layout) ->
    $.slidea.layouts[name] = layout

  # Lightweight plugin wrapper that prevents multiple instantiations.
  #
  $.fn.slidea = (opts) ->
    @each (index, element) ->
      unless $.data element, "slidea"
        $.data element, "slidea", new $.slidea element, opts

) window.jQuery, window, document


# A factory that uses AMD, CommonJS or window globals to
# create the jQuery plugin.
# do (plugin = slidea, window) ->
#   hasDefine  = typeof define is "function" and define.amd?
#   hasExports = typeof module isnt "undefined" and module.exports?
#
#   # AMD.
#   if hasDefine
#     define ["jquery"], plugin
#
#   # NodeJS/CommonJS.
#   else if hasExports
#     module.exports = plugin require "jquery"
#
#   # Window globals.
#   else
#     plugin window.jQuery or window.$
